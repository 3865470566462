import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import {
  faCodeBranch,
  faGraduationCap,
  faHardHat,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons"
import TopBlock from "../components/hireMe/topBlock"
import Title from "../components/title"
import { HireMeInterface } from "../@types/pages/hire-me"
import tw from "twin.macro"

export const query = graphql`
  query {
    allResumeSkillset {
      edges {
        node {
          skillset
          ResumeSkills {
            skill
          }
        }
      }
    }
  }
`

const HireMePage: React.FC<HireMeInterface> = ({ data }) => {
  const Article = styled.article`
    ${tw`
      flex flex-col items-center self-center
      md:w-8/12
     `};
  `

  const Skillsets = styled.section`
    ${tw`
      flex flex-row flex-wrap 
      `};
  `

  const SkillsetTitle = styled.h2`
    ${tw`text-xl font-black`};
  `

  const Skillset = styled.section`
    ${tw`
      mt-4 w-6/12
      md:w-4/12
     `};
  `

  const Skills = styled.ul`
    ${tw`list-disc list-inside mt-2`};
  `

  const H1 = styled.h1`
    ${tw`text-2xl font-black`};
  `

  const WhatICanDoForYou = styled.section`
    ${tw`mt-6 border-b border-dashed mb-6 pb-6`};
  `

  const A = styled.a`
    ${tw`font-semibold text-linkcolor hover:text-linkhovercolor`}
  `

  const TitleContainer = styled.div`
    ${tw`md:w-full`};
  `

  return (
    <Layout>
      <SEO title="Hire me" />
      <Article>
        <TitleContainer>
          <Title title={"Hire me"} />
        </TitleContainer>
        <WhatICanDoForYou>
          <H1>What I bring to the table</H1>
          <TopBlock icon={faHardHat} title={"Architect"}>
            You want to create a new software product and need someone to design
            and build it for you? I can help you decide on an architecture that
            fits your needs and help you get started
          </TopBlock>
          <TopBlock icon={faCodeBranch} title={"Development"}>
            You have a legacy application that needs to be maintained.
            Continuing developing a legacy application can be tricky. I can help
            with continuing development and modernizing while maintaining it.
          </TopBlock>
          <TopBlock icon={faGraduationCap} title={"Training"}>
            Your developers are valuable and the development industry moves
            really fast. You can hire me to train your developers in using
            modern programming practices like OOP, applying SOLID practices and
            building maintainable applications.
          </TopBlock>
          <TopBlock icon={faPlaneDeparture} title={"Deployment"}>
            Do you have an application that is hard to deploy? I can help
            improving your deployment strategy by automating the deployment
            itself and even automate testing if the deployment is successful.
          </TopBlock>
        </WhatICanDoForYou>
        <Skillsets>
          <H1>My Skillset</H1>
          <p>
            If your project uses something not listed below, I can pick up new
            libraries & frameworks fairly quickly. Don&apos;t hesitate to{" "}
            <A href={"/contact"}>contact me</A> if you want to find out more.
          </p>
          {data.allResumeSkillset.edges.map(({ node }, i) => (
            <Skillset key={"skillset-" + i}>
              <SkillsetTitle>{node.skillset}</SkillsetTitle>
              <Skills>
                {node.ResumeSkills.map(({ skill }, i) => (
                  <li key={"skill-" + i}>{skill}</li>
                ))}
              </Skills>
            </Skillset>
          ))}
        </Skillsets>
      </Article>
    </Layout>
  )
}

export default HireMePage
