import React from "react"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TopBlockProps } from "../../@types/components/hireMe/topBlock"
import tw from "twin.macro"

const TopBlock: React.FC<TopBlockProps> = ({ children, icon, title }) => {
  const Container = styled.section`
    ${tw`flex flex-row mt-4`};
  `

  const Icon = styled(FontAwesomeIcon)`
    ${tw`text-3xl mt-2`}
  `

  const Title = styled.h1`
    ${tw`text-xl font-black`};
  `

  const Text = styled.div`
    ${tw`ml-4`};
  `

  const P = styled.p`
    ${tw`mt-2`};
  `

  return (
    <Container>
      <Icon icon={icon} />
      <Text>
        <Title>{title}</Title>
        <P>{children}</P>
      </Text>
    </Container>
  )
}

export default TopBlock
